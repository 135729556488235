<template lang="pug">
include ../../../configs/template
div.row
  div(v-if="!statement.is_payed").col-12
    +select('body.is_payed','listPaymentStatus','payment','nameLang')
  div(v-else).col-12
    +select('body.status_document','listStatus','status','nameLang')
  div(v-if="body.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.EDUCATION.STATEMENT_ATC.REJECTED").col-12
    Reject(:statement="statement")
  div(v-if="body.status_document !== STATUSES_STATEMENT_CONSTANTS.SAILOR.EDUCATION.STATEMENT_ATC.REJECTED").col-12
    v-btn(color="success" @click="saveStatusStatement" :loading="isLoading") {{ $t('btn.save') }}
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
const initBody = () => ({
  is_payed: false,
  status_document: null
})

export default {
  name: 'EditStatusEducationStaement',
  components: {
    Reject: () => import('./Reject.vue')
  },
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      STATUSES_STATEMENT_CONSTANTS,
      body: initBody(),
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listPaymentStatus: state => state.directory.paymentStatus
    }),
    ...mapGetters(['statusChoose']),
    listStatus () {
      return this.statusChoose('StatementAdvancedTraining').filter(el =>
        this.statement.status_document === STATUSES_STATEMENT_CONSTANTS.SAILOR.EDUCATION.STATEMENT_ATC.PASSED_EXAM
          ? el.id === STATUSES_STATEMENT_CONSTANTS.SAILOR.EDUCATION.STATEMENT_ATC.APPROVED : el)
    }
  },
  mounted () {
    Object.keys(this.body).forEach(key => (this.body[key] = this.statement[key]))
  },
  methods: {
    ...mapActions(['updateGraduationStatement']),
    async saveStatusStatement () {
      let body = { ...this.body }
      this.isLoading = true
      this.statement.is_payed ? delete body.is_payed : delete body.status_document
      await this.updateGraduationStatement({ ...this.$route.params, body })
      this.isLoading = false
    }
  }
}
</script>
